<template>
  <Breadcrumb v-if="userGroupData" :detailTitle="userGroupData.name" />
  <Grid :settings="gridSettings" :columns="gridColumns" />
</template>
<script>
export default {
  name: "UserGroupDetail",
  components: {},
  data() {
    return {
      gridSettings: {
        action: "UserGroupDetail",
        requestUrl: String.format("/Lcdp-GetUsersFromUserGroup"),
        requestUrlRouteParamNames: [{ key: "id", value: "userGroupId" }],
        routeRequiredParameters: [],

        isPublicIdColumnHide: true,
        isCommandColumnHide: true,
        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,
        autoOrderProcess: true,

        isGatewayRequest: true,

        buttons: [],
      },
      gridColumns: [
        {
          text: this.$t(
            "Users.FirstName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "firstName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Users.LastName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "lastName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Users.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "email",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
      userGroupData: {},
    };
  },
  methods: {
    getUserGroup() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-GetUserGroup?id={0}",
            this.$route.params.userGroupId
          )
        )
        .then((response) => {
          if (response.data !== null) {
            this.userGroupData = response.data;
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getUserGroup();
  },
};
</script>
